import React from 'react'
import "./mystyles.scss"
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

export default function management() {
    return (
        <Layout>
            <SEO />
            <section className="container has-text-light pb-5">
                <p className="title has-text-primary">Talent & Performance Management</p>
                <div className="columns pb-5">
                    <div className="column">
                        <p className="subtitle has-text-light p-3">Talent management is an organization’s commitment to identify, develop, engage and retain the most talented employees available in the job market and within their enterprise. As a business strategy it includes reviewing current employee’s capabilities, determining their potential and performance ability to rise to more senior leadership roles, and developing them to be ready to achieve success in those roles.</p>
                        <p className="subtitle has-text-light p-3">Top Talent will ensure that these fluid and robust processes are efficient, effective and able to achieve the goal of ensuring that your company is led by it’s top talent to outstanding performance.</p>
                    </div>
                    <div className="column">
                        <StaticImage 
                            src="../images/people-sitting-beside-brown-wooden-desk-near-flat-screen-tv-1181355.jpg"
                            alt="people sitting beside brown wooden desk near flat screen tv"
                            placeholder="blurred"
                        />
                    </div>
                </div>
            </section>
            <section className="container has-text-light pb-5">
                <p className="title is-4 has-text-primary">Talent & Performance Management Services:</p>
                <div>
                    
                    <div className="columns pb-5">
                        <div className="column is-4">
                            <StaticImage 
                                src="../images/christina-wocintechchat-com-rg1y72eKw6o-unsplash.jpg"
                                alt="people sitting at brown wooden table"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="column is-8 p-3">
                            <p className="subtitle has-text-light">Talent Reviews</p>
                            <p>A series of in-person or virtual meetings with different levels of management to discuss the performance and potential of people on teams, within functions and/or business units. These reviews may include discussions of all employees or focus on a specific group of employees. Talent reviews require significant planning and preparation prior to the meeting and also require a post-meeting follow-up. Together we would determine the best talent review process for your organization. You would then decide how involved Top Talent would be in the planning, preparation, facilitation and follow-up components.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="columns pb-5">
                        <div className="column is-4">
                            <StaticImage 
                                src="../images/OrgChart.png"
                                alt="company organization chart"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="column is-8">
                            <p className="subtitle has-text-light">Succession and Career Planning</p>   
                            <p>Succession and Career Planning often follows the talent review process. This planning involves the mapping of employees suited to specific roles across the organization. The process enables your company to know who might take a more senior role when the person currently in that role vacates the position. It also takes the career aspirations of the employees into strong consideration. We would partner on determining the right processes to meet your organization’s strategy, ways to develop employees to be ready for future roles and how to document the decisions that are made.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="columns pb-5">
                        <div className="column is-4">
                            <StaticImage 
                                src="../images/amy-hirschi-K0c8ko3e6AA-unsplash.jpg"
                                alt="Two women seated at wooden table looking at a tablet"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="column is-8">
                            <p className="subtitle has-text-light">Performance Management</p>
                            <p>The Performance Management process provides a transformation for many companies. Current research indicates that a forward-looking approach that revolves around frequent conversations between employees, their managers and other stakeholders focused on performance improvement, is the key to success. Top Talent would collaborate on designing and implementing the right process for your employees that propels them to achieve extraordinary results.</p>
                        </div>
                    </div>
                </div>
                <p className="pb-5 pt-5 has-text-centered has-text-primary is-size-4">Judy McHugh, the founder and principal of Top Talent, held positions as Head of Global Talent Development for MetLife and Head of Talent Management and Development at Linde. In those roles and in consulting she has had experience in all aspects of Talent Management and Development.</p>
            </section>
        </Layout>
    )
}
